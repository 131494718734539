<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="10">
      <v-card>
        <v-card-title>
          {{
            $t("crudActions.updateModel", { model: $t("models.groups.single") })
          }}
        </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tab"
            fixed-tabs>
            <v-tab
              key="configuration"
              :class="errorClassForTab('configuration')">
              {{ $t("views.groups.create_or_update.tab_names.configuration") }}
            </v-tab>
            <v-tab
              key="footers"
              :class="errorClassForTab('footers')">
              {{ $t("views.groups.create_or_update.tab_names.footers") }}
            </v-tab>
            <v-tab
              key="cash-outs-and-commissions"
              :class="errorClassForTab('cashOutsAndCommissions')">
              {{ $t("views.groups.create_or_update.tab_names.cash_outs_and_commissions") }}
            </v-tab>
            <v-tab
              key="schedules"
              :class="errorClassForTab('schedules')">
              {{ $t("views.groups.create_or_update.tab_names.schedules") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <validation-observer
              ref="configurationValidationObserver"
              slim>
              <v-tab-item
                key="configuration"
                eager>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.cancelTicketTime"
                  name="cancel_ticket_time"
                  slim>
                  <v-text-field
                    :value="form.group.cancelTicketTime"
                    :label="$t('attributes.groups.cancel_ticket_time')"
                    :error-messages="errors"
                    name="cancelTicketTime"
                    type="text" />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.timeZone"
                  name="cancel_ticket_time"
                  slim>
                  <v-autocomplete
                    v-model="form.group.timeZone"
                    :items="timeZones"
                    :error-messages="errors"
                    item-text="label"
                    item-value="value"
                    label="Time Zone" />
                </validation-provider>
                <v-switch
                  v-model="form.group.winningTicketControl"
                  :label="$t('attributes.groups.winning_ticket_control')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <v-switch
                  v-model="form.group.jackpotEnabled"
                  :label="$t('attributes.groups.jackpot_enabled')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <validation-provider
                  v-if="form.group.jackpotEnabled"
                  v-slot="{ errors }"
                  :rules="validationRules.jackpotRakePercentage"
                  name="jackpot_rake_percentage"
                  slim>
                  <v-text-field
                    v-model="form.group.jackpotRakePercentage"
                    :label="$t('attributes.groups.jackpot_rake_percentage')"
                    :error-messages="errors"
                    name="jackpotRakePercentage"
                    type="text" />
                </validation-provider>
                <validation-provider
                  v-if="form.group.jackpotEnabled"
                  v-slot="{ errors }"
                  :rules="validationRules.minJackpotBet"
                  name="min_jackpot_bet"
                  slim>
                  <v-text-field
                    v-model="form.group.minJackpotBet"
                    :label="$t('attributes.groups.min_jackpot_bet')"
                    :error-messages="errors"
                    name="minJackpotBet"
                    type="text" />
                </validation-provider>
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="footersValidationObserver"
              slim>
              <v-tab-item
                key="footers"
                eager>
                <group-or-pool-footer-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="cashOutsAndCommissionsValidationObserver"
              slim>
              <v-tab-item
                key="cas-outs-and-commissions"
                eager>
                <group-or-pool-cash-out-and-commission-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="schedulesValidationObserver"
              slim>
              <v-tab-item key="schedules">
                <group-or-pool-schedule-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
          </v-tabs-items>
          <v-card-actions>
            <v-btn
              color="success"
              @click="updateGroup">
              {{
                $t("crudActions.update")
              }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchMyGroup, getParams, updateMyGroup } from "@/api/group.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";
import GroupOrPoolFooterForm from "@/components/groupOrPool/GroupOrPoolFooterForm.vue";
import GroupOrPoolCashOutAndCommissionForm from "@/components/groupOrPool/GroupOrPoolCashOutAndCommissionForm.vue";
import GroupOrPoolScheduleForm from "@/components/groupOrPool/GroupOrPoolScheduleForm.vue";
import Weekday from "@/imported/Weekday.json";
import ContentLoader from "../../mixins/ContentLoader";

function data() {
  return {
    groupId: null,
    form: {
      group: {
        cancelTicketTime: null,
        timeZone: null,
        winningTicketControl: false,
        jackpotEnabled: false,
        jackpotRakePercentage: null,
        minJackpotBet: null,
        firstFooter: null,
        secondFooter: null,
        thirdFooter: null,
        fourthFooter: null,
        cashOuts: [
          { weekday: Weekday.sunday, amount: null },
          { weekday: Weekday.monday, amount: null },
          { weekday: Weekday.tuesday, amount: null },
          { weekday: Weekday.wednesday, amount: null },
          { weekday: Weekday.thursday, amount: null },
          { weekday: Weekday.friday, amount: null },
          { weekday: Weekday.saturday, amount: null },
        ],
        commission: null,
        schedules: [
          { weekday: Weekday.sunday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.monday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.tuesday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.wednesday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.thursday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.friday, openingTime: "08:00", closingTime: "18:00" },
          { weekday: Weekday.saturday, openingTime: "08:00", closingTime: "18:00" },
        ],
      },
    },
    tab: null,
    timeZones: [],
  };
}

export default {
  name: "Configuration",
  components: {
    ValidationObserver,
    GroupOrPoolFooterForm,
    GroupOrPoolCashOutAndCommissionForm,
    GroupOrPoolScheduleForm,
  },
  mixins: [ContentLoader, Validatable],
  data,
  computed: {
    validationRules() {
      return {
        cancelTicketTime: "numeric",
        timeZone: "required",
        winningTicketControl: "required",
        jackpotRakePercentage: "required",
        minJackpotBet: "required",
        firstFooter: "required",
        secondFooter: "required",
        thirdFooter: "required",
        fourthFooter: "required",
        cashOuts: "required|numeric|between:0,100",
        commission: "required|numeric|between:0,30",
        schedules: "required",
      };
    },
  },
  created() {
    this.loadFormData();
  },
  methods: {
    async updateGroup() {
      const isValid = await this.validateForm();

      if (isValid) {
        const submitForm = {};
        delete Object.assign(submitForm, this.form.group, { ["groupCashOutsAttributes"]: this.form.group.cashOuts }).cashOuts;
        delete Object.assign(submitForm, submitForm, { ["groupSchedulesAttributes"]: this.form.group.schedules }).schedules;
        delete Object.assign(submitForm, submitForm, { ["usersAttributes"]: [this.form.group.user] }).user;

        updateMyGroup(this.groupId, submitForm).
          then(() => {
            // this.$router.push({
            //   name: this.$routes.group.indexRoute.name,
            // });
          }).
          catch(error => console.log(error));
      }
    },
    async validateForm() {
      const {
        configurationValidationObserver,
        footersValidationObserver,
        cashOutsAndCommissionsValidationObserver,
        schedulesValidationObserver,
      } = this.$refs;

      return await configurationValidationObserver.validate() &&
        await footersValidationObserver.validate() &&
        await cashOutsAndCommissionsValidationObserver.validate() &&
        await schedulesValidationObserver.validate();
    },
    loadFormData() {
      // resetData(this.$data, data);
      this.loadGroup();
      this.fetchParams();
    },
    fetchParams() {
      return getParams().
        then(response => {
          const { timeZones } = response.data;
          this.timeZones = Object.keys(timeZones).
            map(key => ({ label: key, value: timeZones[key] }));
        });
    },
    loadGroup() {
      return fetchMyGroup().
        then(response => {
          const { group } = response.data;
          this.groupId = group.id;
          this.form.group = {
            ...group,
          };
        });
    },
    errorClassForTab(tabName) {
      if (typeof this.$refs[tabName + "ValidationObserver"] !== "undefined") {
        const validationObserver = this.$refs[tabName + "ValidationObserver"];

        return validationObserver.flags.failed
          ? "tab-with-error"
          : "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.tab-with-error {
  background: #ffb8b8;
  border-top: 2px solid red;

}

// Reversed input variant
::v-deep .v-input--selection-controls .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 30px;

  & > * {
    flex: inherit;
  }

  div {
    margin-left: 10px;
  }
}


</style>
