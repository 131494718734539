var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("crudActions.updateModel", { model: _vm.$t("models.groups.single") }))+" ")]),_c('v-card-text',[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"configuration",class:_vm.errorClassForTab('configuration')},[_vm._v(" "+_vm._s(_vm.$t("views.groups.create_or_update.tab_names.configuration"))+" ")]),_c('v-tab',{key:"footers",class:_vm.errorClassForTab('footers')},[_vm._v(" "+_vm._s(_vm.$t("views.groups.create_or_update.tab_names.footers"))+" ")]),_c('v-tab',{key:"cash-outs-and-commissions",class:_vm.errorClassForTab('cashOutsAndCommissions')},[_vm._v(" "+_vm._s(_vm.$t("views.groups.create_or_update.tab_names.cash_outs_and_commissions"))+" ")]),_c('v-tab',{key:"schedules",class:_vm.errorClassForTab('schedules')},[_vm._v(" "+_vm._s(_vm.$t("views.groups.create_or_update.tab_names.schedules"))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('validation-observer',{ref:"configurationValidationObserver",attrs:{"slim":""}},[_c('v-tab-item',{key:"configuration",attrs:{"eager":""}},[_c('validation-provider',{attrs:{"rules":_vm.validationRules.cancelTicketTime,"name":"cancel_ticket_time","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.form.group.cancelTicketTime,"label":_vm.$t('attributes.groups.cancel_ticket_time'),"error-messages":errors,"name":"cancelTicketTime","type":"text"}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validationRules.timeZone,"name":"cancel_ticket_time","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.timeZones,"error-messages":errors,"item-text":"label","item-value":"value","label":"Time Zone"},model:{value:(_vm.form.group.timeZone),callback:function ($$v) {_vm.$set(_vm.form.group, "timeZone", $$v)},expression:"form.group.timeZone"}})]}}])}),_c('v-switch',{staticClass:"v-input--reverse",attrs:{"label":_vm.$t('attributes.groups.winning_ticket_control'),"color":"primary","hide-details":""},model:{value:(_vm.form.group.winningTicketControl),callback:function ($$v) {_vm.$set(_vm.form.group, "winningTicketControl", $$v)},expression:"form.group.winningTicketControl"}}),_c('v-switch',{staticClass:"v-input--reverse",attrs:{"label":_vm.$t('attributes.groups.jackpot_enabled'),"color":"primary","hide-details":""},model:{value:(_vm.form.group.jackpotEnabled),callback:function ($$v) {_vm.$set(_vm.form.group, "jackpotEnabled", $$v)},expression:"form.group.jackpotEnabled"}}),(_vm.form.group.jackpotEnabled)?_c('validation-provider',{attrs:{"rules":_vm.validationRules.jackpotRakePercentage,"name":"jackpot_rake_percentage","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.groups.jackpot_rake_percentage'),"error-messages":errors,"name":"jackpotRakePercentage","type":"text"},model:{value:(_vm.form.group.jackpotRakePercentage),callback:function ($$v) {_vm.$set(_vm.form.group, "jackpotRakePercentage", $$v)},expression:"form.group.jackpotRakePercentage"}})]}}],null,false,928589053)}):_vm._e(),(_vm.form.group.jackpotEnabled)?_c('validation-provider',{attrs:{"rules":_vm.validationRules.minJackpotBet,"name":"min_jackpot_bet","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.groups.min_jackpot_bet'),"error-messages":errors,"name":"minJackpotBet","type":"text"},model:{value:(_vm.form.group.minJackpotBet),callback:function ($$v) {_vm.$set(_vm.form.group, "minJackpotBet", $$v)},expression:"form.group.minJackpotBet"}})]}}],null,false,3833323649)}):_vm._e()],1)],1),_c('validation-observer',{ref:"footersValidationObserver",attrs:{"slim":""}},[_c('v-tab-item',{key:"footers",attrs:{"eager":""}},[_c('group-or-pool-footer-form',{attrs:{"group-or-pool":_vm.form.group,"validations":_vm.validationRules},on:{"update:groupOrPool":function($event){return _vm.$set(_vm.form, "group", $event)},"update:group-or-pool":function($event){return _vm.$set(_vm.form, "group", $event)}}})],1)],1),_c('validation-observer',{ref:"cashOutsAndCommissionsValidationObserver",attrs:{"slim":""}},[_c('v-tab-item',{key:"cas-outs-and-commissions",attrs:{"eager":""}},[_c('group-or-pool-cash-out-and-commission-form',{attrs:{"group-or-pool":_vm.form.group,"validations":_vm.validationRules},on:{"update:groupOrPool":function($event){return _vm.$set(_vm.form, "group", $event)},"update:group-or-pool":function($event){return _vm.$set(_vm.form, "group", $event)}}})],1)],1),_c('validation-observer',{ref:"schedulesValidationObserver",attrs:{"slim":""}},[_c('v-tab-item',{key:"schedules"},[_c('group-or-pool-schedule-form',{attrs:{"group-or-pool":_vm.form.group,"validations":_vm.validationRules},on:{"update:groupOrPool":function($event){return _vm.$set(_vm.form, "group", $event)},"update:group-or-pool":function($event){return _vm.$set(_vm.form, "group", $event)}}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.updateGroup}},[_vm._v(" "+_vm._s(_vm.$t("crudActions.update"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }